import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

import { Layout } from 'src/components/Layout';
import { ArrowButton } from 'src/components/ArrowButton';
import { TextInput } from 'src/components/TextInput';
import { UserManager } from 'src/helpers/UserManager';
import { navigate } from 'gatsby';
import { Spinner } from 'src/components/Spinner';
import { isEmailValid, isPwdValid } from 'src/helpers/SignUpHelper';
import { AfterEmailConfirmation } from 'src/components/AfterEmailConfirmation';

interface Props {}

interface State {
	loading: boolean;
	success: boolean;
	email: string;
	password: string;
	token?: string;
	buttonPressed: boolean;
	resetFailed: boolean;
}

export default class PasswordReset extends React.Component<Props, State> {
	state: State = {
		loading: true,
		success: false,
		email: '',
		password: '',
		buttonPressed: false,
		resetFailed: false,
	};

	componentDidMount = async () => {
		let urlParams;
		// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
		if (typeof window !== 'undefined') {
			urlParams = new URLSearchParams(window.location.search);
		}
		const email = decodeURIComponent((urlParams ? urlParams.get('email') : undefined) || '');
		const token = (urlParams ? urlParams.get('token') : undefined) || undefined;
		this.setState({ loading: false, email, token });
	};

	handleForgotPassword = async () => {
		const { email, password, token } = this.state;
		this.setState({ buttonPressed: true });
		if (token) {
			const result = await UserManager.redeemUserPasswordResetLink({ email, password, token });
			if (result) {
				navigate('/sign-in');
			} else {
				console.warn(`Error redeeming provided token`);
				this.setState({ resetFailed: true, buttonPressed: false, token: undefined });
			}
		} else {
			await UserManager.sendUserPasswordResetLinkToEmail(email);
			this.setState({ success: true });
		}
	};

	render() {
		const { loading, success, email, password, token, buttonPressed, resetFailed } = this.state;
		const hasValidResponses = token ? isPwdValid(password) : isEmailValid(email);
		const caption = token
			? buttonPressed
				? 'Processing new password...'
				: 'Update Password'
			: buttonPressed
			? 'Email sent'
			: 'Send Password Reset Link';
		const emailBody = `Hi Support,%2c%0a%0aCan you please assist me to reset the password for my Xceptional account with email ${email}%0a%0a`;
		const emailString = `mailto:?to=support@xceptional.io&subject=Please reset my password&body=${emailBody}`;
		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? (
						!success ? (
							<>
								<h1 className={classNames(styles.heading, styles.headingContainer)}>
									Password Reset
								</h1>
								{token ? (
									<h2 className={styles.pText}>Enter your new password below:</h2>
								) : !resetFailed ? (
									<h2 className={styles.pText}>
										Enter your email, and we’ll send you a link to reset your password:
									</h2>
								) : (
									<h2 className={styles.pText}>
										Password reset failed. Click below to send a new link or{' '}
										<a href={emailString}>contact us</a> for assistance.
									</h2>
								)}
								<div className={styles.innerLogin}>
									<div className={styles.emailAndPasswordContainer}>
										<div className={styles.padRightContainer}>
											{token ? (
												<>
													<TextInput
														caption={'Password'}
														value={password}
														isPasswordInput={true}
														onValueChanged={(textValue) => {
															this.setState({ password: textValue });
														}}
														validate={(textValue) => isPwdValid(textValue)}
														onEnter={this.handleForgotPassword}
													/>
													<div className={styles.passwordText}>
														Passwords require 8+ characters with a letter, number, and symbol
													</div>
												</>
											) : (
												<TextInput
													caption={'Email address'}
													value={email}
													onValueChanged={(textValue) => {
														this.setState({ email: textValue });
													}}
													validate={(textValue) => isEmailValid(textValue)}
													onEnter={this.handleForgotPassword}
													validationMsg={'Please provide a valid email'}
												/>
											)}
										</div>
									</div>
								</div>
								<ArrowButton
									caption={caption}
									onClick={this.handleForgotPassword}
									isDisabled={!hasValidResponses || buttonPressed}
								/>
							</>
						) : (
							<AfterEmailConfirmation
								headingText="Check your inbox"
								bodyText={`Check your inbox for a verification email to continue.`}
							/>
						)
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
